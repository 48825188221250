import React from 'react'
import styled from 'styled-components'
import {
  desktopVw,
  desktopBreakpoint,
  mobileVw,
  letterSpacing,
  fonts,
} from '../../styles'
import { Button } from '../shared'

export const FormBlock = ({ firstName, lastName, email, phone, message }) => {
  return (
    <FormBlockWrapper>
      <StyledForm
        name='contact'
        method='post'
        data-netlify='true'
        id='contact-form'
        data-netlify-honeypot='bot-field'
        aria-label='Contact form'
      >
        <input
          type='hidden'
          name='form-name'
          value='contact'
          id='formblock-email'
        />
        <StyledTitle>Contactez-Nous</StyledTitle>
        <label htmlFor='firstName'>
          <Title>{firstName}</Title>
        </label>
        <input placeholder={firstName} name='firstName' type='text' required />
        <label htmlFor='lastName'>
          <Title>{lastName}</Title>
        </label>
        <input placeholder={lastName} name='lastname' type='text' required />
        <label htmlFor='email'>
          <Title>{email}</Title>
        </label>
        <input placeholder={email} type='email' name='email' required />
        <label htmlFor='phone'>
          <Title>{phone}</Title>
        </label>
        <input placeholder={phone} type='tel' name='phone' />
        <label htmlFor='message'>
          <Title>{message}</Title>
        </label>
        <textarea rows='5' type='text' placeholder={message} name='message' />
        <Note>*Champs obligatoires.</Note>
        <StyledButton text='Envoyer' type='submit' />
      </StyledForm>
    </FormBlockWrapper>
  )
}

const FormBlockWrapper = styled.div`
  display: grid;
  margin: 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: 1fr 1fr;
    margin: ${desktopVw(0)} 0 0;
    padding: ${desktopVw(32)} ${desktopVw(128)};
  }
`

const StyledTitle = styled.h2`
  text-transform: uppercase;
  margin-bottom: ${mobileVw(16)};
  font-family: 'Tivoli Bold';
  font-size: ${mobileVw(20)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(32)};
    font-size: ${desktopVw(28)};
    letter-spacing: ${desktopVw(2.8)};
  }
`

const StyledForm = styled.form`
  display: block;

  label {
    text-transform: uppercase;
  }
  textarea {
    resize: none;
    margin: ${mobileVw(10)} 0 ${mobileVw(10)} 0;

    @media (min-width: ${desktopBreakpoint}) {
      margin: ${desktopVw(6)} 0 ${desktopVw(14)} 0;
    }
  }
  input {
    margin: ${mobileVw(10)} 0 ${mobileVw(17)} 0;

    @media (min-width: ${desktopBreakpoint}) {
      margin: ${desktopVw(6)} 0 ${desktopVw(32)} 0;
    }
  }
  input,
  textarea {
    width: 100%;
    border: ${mobileVw(0.5)} solid #000;
    background: transparent;
    font-size: ${mobileVw(14)};
    padding: ${mobileVw(11)} ${mobileVw(16)};

    font-family: 'MrsEaves';
    outline: none;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: ${desktopVw(16)};
      padding: ${desktopVw(15)} ${desktopVw(14)};

      border: ${desktopVw(0.5)} solid #000;
    }

    &::placeholder {
      color: #bebbbb;
      font-family: 'MrsEaves';
    }
  }
`

const Note = styled.h5`
  margin-bottom: ${mobileVw(16)};
  font-size: ${mobileVw(0)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(32)};
    font-size: ${desktopVw(14)};
    font-family: ${fonts.mrs};
  }
`

const StyledButton = styled(Button)`
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(350)};
    padding: ${desktopVw(22)} 0 ${desktopVw(16)};
  }
`

const Title = styled.h4`
  font-family: 'Tivoli Bold';
  font-size: ${mobileVw(14)};
  letter-spacing: ${letterSpacing(100)};
  line-height: ${mobileVw(14)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(16)};
  }
`
