import React, { useRef } from 'react'
import styled from 'styled-components'
import { useWindowScroll } from 'react-use'
import Image from 'gatsby-image'
import { RichText } from '../shared'

import {
    desktopVw,
    desktopBreakpoint,
    mobileVw,
    letterSpacing,
    colors,
} from '../../styles'

export const HistoryBlock = ({ items }) => {
    const scrollRef = useRef(null)
    const { y } = useWindowScroll()

    const scrollProgress = () => {
        if (scrollRef.current) {
            if (y >= scrollRef.current.offsetHeight) {
                return 100
            }
            return y / (scrollRef.current.offsetHeight / 100)
        }
    }

    return (
        <HistoryBlockWrapper ref={scrollRef}>
            {items &&
                items.map((item, i) => (
                    item.title &&
                    <StoryItemWrapper
                        extraMargin={item.year === '1989'}
                        key={item.id + i}
                        year={item.year}
                    >
                        <Year>{item.year}</Year>
                        <Title>{item.title}</Title>
                        <StyledImage fluid={item.image.fluid} alt={item.image.title} />
                        <RichText json={item.text} />
                    </StoryItemWrapper>
                ))}
            <ProgressBar>
                <Progress progressLevel={scrollProgress()} />
            </ProgressBar>
        </HistoryBlockWrapper>
    )
}

const HistoryBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${mobileVw(24)} 0 ${mobileVw(40)};
  position: relative;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(92)} ${desktopVw(128)} ${desktopVw(140)};
  }
`

const StoryItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${mobileVw(36)};
  position: relative;
  :first-child {
    margin-top: 0;
  }
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(444)};
    &::after {
      content: "${(props) => props.year}";
      position: absolute;
      top: -${desktopVw(40)};
      right: -${desktopVw(128)};
      font-size: ${desktopVw(200)};
      color: ${colors.white};
      opacity: 0.11;
      font-family: "Tivoli Bold";
      letter-spacing: ${letterSpacing(20)};
      width: fit-content;
    }
    :nth-child(2n + 1) {
      align-self: flex-end;
      margin-top: 0;
    }
    :nth-child(2n) {
      align-self: flex-start;
      margin-top: ${(props) =>
        props.extraMargin ? `-${desktopVw(300)}` : `-${desktopVw(400)}`};
      &::after{
        left: -${desktopVw(128)};
        top:-${desktopVw(120)};
      }
    }
  }
  p {
      padding-bottom: ${mobileVw(16)};
      font-size: ${mobileVw(16)};
      line-height: ${mobileVw(20)};
      letter-spacing: 0;
      font-family: MrSeaves;
      white-space: pre-line;
      @media (min-width: ${desktopBreakpoint}) {
        padding-bottom: 0;
        font-size: ${desktopVw(18)};
        line-height: ${desktopVw(24)};
        margin-top: ${desktopVw(25)};
      }
    }
`

const Year = styled.span`
  font-family: 'Tivoli Bold';
  font-size: ${mobileVw(16)};
  font-weight: ${100};
  text-align: left;
  letter-spacing: ${letterSpacing(2.8)};
  color: ${colors.mailleGold};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(28)};
  }
`

const Title = styled.h2`
  font-family: 'Splandor';
  padding: ${mobileVw(8)} 0 ${mobileVw(16)} 0;
  font-size: ${mobileVw(30)};
  line-height: ${mobileVw(30)};
  text-align: left;
  color: ${colors.white};
  padding: ${mobileVw(6)} 0 ${mobileVw(12)} 0;
  letter-spacing: 0;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    line-height: ${desktopVw(46)};
    padding: ${desktopVw(8)} 0 ${desktopVw(16)} 0;
  }
`

const StyledImage = styled(Image)`
  order: 4;

  @media (min-width: ${desktopBreakpoint}) {
    order: unset;
  }
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: ${mobileVw(15)};
  width: ${mobileVw(1)};
  height: 100%;
  background: white;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(2)};
    left: ${`calc(50% - (${desktopVw(2)} / 2))`};
  }
`

const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => `${props.progressLevel}%`};
  background: ${colors.mailleGold};
  transition: height 0.3s linear;
`
