import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import { HeroBlock, Decoration, LinkType, SEO } from '../components/shared'

import { HistoryBlock } from '../components/pageBlocks'
import { FeaturedVideoWrapper } from "../templates/LaMaisonMaille"
import { Space, FeaturedItemBlock } from '../components/shared'

import {
  mobileVw,
  desktopVw,
  colors,
  desktopBreakpoint,
  fonts,
  letterSpacing,
} from '../styles'
import { useLang, useLocale } from '../hooks'

const History = ({ location, data: { contentfulPageHistory } }) => {
  const {
    heroblock,
    historyItems,
    decorations,
    seoDescription,
    seoTitle,
    previousPageTitle,
    historyPageVideo
  } = contentfulPageHistory
  const lang = useLang();
  const locale = useLocale()
  return (
    <StyledHistorySection>
      <SEO description={seoDescription} title={seoTitle} pageType='History' />
      <HeroBlock data={heroblock} marginTitle={[80]} fontSize='60' />
      <StyledDecoration>
        {decorations.map((item, i) => {
          return <Decoration key={i} data={item} />
        })}
      </StyledDecoration>
      {/*Added history video */}
      <Space l={3} s={2} />
      {historyPageVideo && <FeaturedVideoWrapper>
        <FeaturedItemBlock data={historyPageVideo} title={historyPageVideo.title} hideRating />
      </FeaturedVideoWrapper>}

      <HistoryWrapper>
        <HistoryBlock items={historyItems} />
      </HistoryWrapper>
      <Breadcrumbs className='breadcrumbs-wrapper'>
        <>
          <LinkType
            to={`/${lang}${heroblock.breadcrumbs[1].link.slug}`}
            alt={`Ce lien va ouvrir une nouvelle fenêtre: ${heroblock.title}`}
          >
            <span style={{ color: `${colors.mailleGold}` }}>
              {'< '}{previousPageTitle} {heroblock.breadcrumbs[1].title}
            </span>
          </LinkType>
        </>
      </Breadcrumbs>
    </StyledHistorySection>
  )
}

const StyledHistorySection = styled.div`
  background: ${colors.black};
  overflow: hidden;
`

const StyledDecoration = styled.div`
  z-index: 2;
  position: relative;
  margin-bottom: ${mobileVw(29)};

  @media (min-width: ${desktopBreakpoint}) {
    /* I accedintally positioned from hero image */
    width: ${desktopVw(858)};
    margin: 0 0 ${desktopVw(25)} auto;
    z-index: 5
  }
`

const Breadcrumbs = styled.div`
  font-size: ${mobileVw(14)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${letterSpacing(100)};
  line-height: ${mobileVw(14)};
  text-transform: uppercase;
  color: ${colors.white};
  margin: ${mobileVw(16)};

  a {
    :nth-child(2) {
      color: ${colors.mailleGold};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    text-align: center;
    margin: ${desktopVw(16)} 0;
    display: block;
    width: 100%;
    font-size: ${desktopVw(16)};
    line-height: ${desktopVw(16)};
  }

  span:last-child {
    color: ${colors.mailleGold};
  }
`

const HistoryWrapper = styled.div`
  background-color: ${colors.black};
  position: relative;
  z-index: 0;
  p {
    color: ${colors.white};
  }
`

export const history = graphql`
  query History($id: String!, $node_locale: String!) {
    contentfulPageHistory(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      seoDescription
      seoTitle
      id
      thumbnailImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      slug
      previousPageTitle
      historyItems {
        title
        year
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        text {
            raw
        }
      }
      heroblock {
        title
        breadcrumbsTitle
        marginBottom
        background
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      decorations {
        image {
          fluid(maxWidth: 2400) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      historyPageVideo {
        title
        buttonText
        featuredItem {
          ... on ContentfulComponentVideo {
            name
            poster {
              fluid {
                src
              }
            }
            videoMov {
              file {
                url
              }
            }
            videoMp4 {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default History
