import React, { useState } from 'react'
import styled from 'styled-components'
import {
    desktopVw,
    desktopBreakpoint,
    mobileVw,
    letterSpacing,
    colors,
} from '../../styles'
import { RichText } from '../shared'
import { Icon } from '../shared/Icon'

export const FaqBlock = ({ items }) => {
    const [currentOpen, setCurrentOpen] = useState(null)
    return (
        <FaqWrapper>
            {items &&
                items.map((item, i) => {
                    return (
                        <AccordionItemWrapper key={i}>
                            <AccordionButton
                                onClick={
                                    currentOpen === i
                                        ? () => setCurrentOpen(null)
                                        : () => setCurrentOpen(i)
                                }
                            >
                                {item.icon && <StyledIcon name={item.icon} stroke={colors.mailleGold} />}
                                <Title>{item.title}</Title>
                                <Arrow name='arrow-down' stroke={colors.mailleGold} />
                            </AccordionButton>
                            {currentOpen === i && (
                                <AccordionContent>
                                    <RichText json={item.text} />
                                </AccordionContent>
                            )}
                        </AccordionItemWrapper>
                    )
                })}
        </FaqWrapper>
    )
}

const FaqWrapper = styled.div`
  padding: ${mobileVw(20)} ${mobileVw(16)} 0;
  background-color: #fff;
  min-height: 10vh;
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(111)} ${desktopVw(128)} 0;
  }
`

const AccordionItemWrapper = styled.div`
  border-bottom: 1px solid ${colors.mediumGrey};
  margin: 0 0 ${mobileVw(20)};
  padding: 0 0 ${mobileVw(20)};

  &:last-child {
    margin: 0 0 ${mobileVw(25)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 ${desktopVw(36)};
    margin: 0 0 ${desktopVw(36)};

    &:last-child {
      margin: 0 0 ${desktopVw(50)};
    }
  }
`

const AccordionButton = styled.button`
  position: relative;
  width: 100%;
  display: flex;
  /* align-content: center; */
  cursor: pointer;
`

const Title = styled.h2`
  max-width: ${mobileVw(250)};
  margin: 0 0 0 ${mobileVw(24)};
  font-size: ${mobileVw(16)};
  line-height: 1;
  font-family: 'Tivoli Bold';
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: ${letterSpacing(100)};
  text-align: left;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(28)};
    margin: 0 0 0 ${desktopVw(64)};
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`

const Arrow = styled(Icon)`
  position: absolute;
  right: 0;
  top: ${mobileVw(4)};
  width: ${mobileVw(12)};
  height: ${mobileVw(6)};

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVw(7)};
    width: ${desktopVw(24.03)};
    height: ${desktopVw(12.01)};
  }
`

const AccordionContent = styled.div`
  padding: 0 0 ${mobileVw(17)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${mobileVw(16)};
    font-family: 'Tivoli Bold';
    color: ${colors.mailleGold};
    letter-spacing: ${letterSpacing(100)};
    margin: ${mobileVw(16)} 0 ${mobileVw(16)};
    text-transform: uppercase;
  }

  p {
    font-family: 'MrSeaves';
    font-size: ${mobileVw(16)};
    line-height: ${mobileVw(20)};
    color: ${colors.black};

    a {
      border-bottom: solid ${mobileVw(1)} ${colors.black};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 ${desktopVw(30)};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: ${desktopVw(28)};
      margin: ${desktopVw(50)} 0 ${desktopVw(18)};
      line-height: ${desktopVw(26)};
    }

    p {
      font-size: ${desktopVw(20)};
      line-height: ${desktopVw(24)};
      a {
        border-bottom: solid ${desktopVw(1)} ${colors.black};
      }
    }
  }
`
