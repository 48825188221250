import React, { useState, useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { CollectionSlider } from '../components/shared/sliders'
import { useLang } from '../hooks'
import VideoSchema from '../components/shared/VideoSchema'
import { useWindowSize } from 'react-use'
import {
  HeroBlock,
  FeaturedItemBlock,
  FeaturedArticlesBlock,
  LinkType,
  RichText,
  Space,
  SEO,
} from '../components/shared'
import { Button } from '../components/shared/Button'

import {
  mobileVw,
  desktopVw,
  fonts,
  colors,
  desktopBreakpoint,
} from '../styles'

const MaisonMaille = ({ location, data: { contentfulPageLaMaisonMaille } }) => {
  const {
    heroblock,
    mobileHeroImage,
    featuredItem,
    featuredSection,
    featuredSectionText,
    video,
    relatedArticles,
    seoDescription,
    seoTitle,
    historyVideo,
    articleCategory,
  } = contentfulPageLaMaisonMaille
  const { width: windowWidth } = useWindowSize()

  const lang = useLang();

  return (
    <StyledRecipeCollection>
      <SEO
        description={seoDescription}
        location={location}
        title={seoTitle}
        pageType='Maison maille'
      />
        <VideoSchema 
            schema={{
            "@context": "http://schema.org/",
            "@type": "ItemList",
            "itemListElement": [
                historyVideo && {"@type": "VideoObject",
                'name': seoTitle && seoTitle,
                'description': seoDescription && seoDescription,
                "uploadDate": "2020-07-19",
                "thumbnailUrl": historyVideo?.featuredItem.poster.fluid.src,
                "contentUrl": historyVideo?.featuredItem.videoMp4.file.url
                },
                video && {"@type": "VideoObject",
                'name': seoTitle && seoTitle,
                'description': seoDescription && seoDescription,
                "uploadDate": "2020-07-19",
                "thumbnailUrl": video?.featuredItem.poster.fluid.src,
                "contentUrl": video?.featuredItem.videoMp4.file.url
                },
              ]
            }}
      />
      {windowWidth < 1024 && (
        <HeroWrapper className='mobileOnly'>
          <HeroBlock
            data={{
              ...heroblock,
              imagesSlider: [mobileHeroImage],
              marginBottom: ['0', '0'],
            }}
          />
        </HeroWrapper>
      )}
      {windowWidth >= 1024 && (
        <HeroWrapper className='desktopOnly'>
          <HeroBlock data={{ ...heroblock, marginBottom: ['0', '0'] }} />
        </HeroWrapper>
      )}
      <div style={{ backgroundColor: `${colors.black}` }}>
        {featuredSection ? (
          <div>
            <FeaturedSectionWrapper>
              <LinkType
                to={`/${lang}${featuredSection.featuredItem.slug}`}
                alt={`Ce lien va ouvrir une nouvelle fenêtre: ${featuredSection.title}`}
              >
                <FeaturedItemBlock
                  data={featuredSection}
                  title={featuredSection.title}
                  hideRating
                />
              </LinkType>
            </FeaturedSectionWrapper>
            <TextWrapper>
              <StyledSpan>
                <RichText json={featuredSectionText} />
              </StyledSpan>
            </TextWrapper>
          </div>
        ) : null}
        {/*For articles page show the Featured Item else for La maison page show
         Video for La maison picture replacement */}
        {
          featuredItem &&
          <>
            {
              !historyVideo ? <> <FeaturedItemWrapper>
                <LinkType
                  to={`/${lang}${featuredItem.featuredItem.slug}`}
                  alt={`Ce lien va ouvrir une nouvelle fenêtre: ${featuredItem.title}`}
                >
                  <FeaturedItemBlock
                    data={featuredItem}
                    title={featuredItem.title}
                    hideRating
                  />
                </LinkType>
              </FeaturedItemWrapper></>
                :
                <>
                  <FeaturedVideoWrapper>
                    <FeaturedItemBlock
                      data={historyVideo}
                      title={historyVideo.title}
                      hideRating
                    />
                  </FeaturedVideoWrapper>
                  <LinkType
                    to={`/${lang}${featuredItem.featuredItem.slug}`}
                    alt={`Ce lien va ouvrir une nouvelle fenêtre: ${featuredItem.title}`}
                  >
                    <StyledButton
                      text={featuredItem.buttonText}
                    />
                  </LinkType>
                </>
            }
          </>
        }
        <Space l={3} s={2} />
        <FeaturedVideoWrapper>
          <FeaturedItemBlock data={video} title={video.title} hideRating />
        </FeaturedVideoWrapper>
        {
          articleCategory &&
          <div style={{
            backgroundColor: "white",
            overflow: 'hidden'
          }}>
            {articleCategory.map((collection, i) => (
              <CollectionSlider
                key={i}
                index={i}
                titleHeading={collection.title}
                slides={collection.slides}
              />
            ))}
          </div>
        }

        <Space l={2} s={2} />
      </div>
    </StyledRecipeCollection >
  )
}

const StyledRecipeCollection = styled.div``

const FeaturedItemWrapper = styled.div`
  background-color: ${colors.black};
  padding: 0 ${mobileVw(16)} 0;

  .featured-image {
    height: ${mobileVw(300)};
  }

  span {
    display: inline-block;
    padding-right: ${mobileVw(5)};
    color: ${colors.white};
    line-height: ${mobileVw(33)};
  }

  .subtitle-section {
    display: none;
  }

  button {
    width: ${mobileVw(288)};
    margin: ${mobileVw(8)} auto 0;
  }

  h2 {
    line-height: ${mobileVw(23)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(260)} 0;

    h2 {
      font-size: ${desktopVw(54)};
      padding-left: 0;
      max-width: 100%;
      line-height: ${desktopVw(40)};
    }

    span {
      padding-bottom: 0;
      line-height: ${desktopVw(38)};
      padding-right: ${desktopVw(5)};

      :nth-child(1),
      :nth-child(2),
      :nth-child(3) {
        padding-right: ${desktopVw(5)};
      }
    }

    button {
      margin-top: ${desktopVw(5)};
      min-width: ${desktopVw(350)};
    }

    .featured-image {
      margin-top: ${desktopVw(5)};
      width: 100%;
      max-height: ${desktopVw(400)};
    }
  }
`

const HeroWrapper = styled.div`
  background-color: ${colors.black};
  padding-bottom: ${mobileVw(26)};
  h1 {
    span {
      :nth-child(2) {
        font-size: ${mobileVw(25)};
      }
    }
  }

  .hero-slider {
    height: ${mobileVw(355)};

    @media (min-width: ${desktopBreakpoint}) {
      height: ${desktopVw(568)};
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    padding-bottom: ${desktopVw(50)};
    h1 {
      font-size: ${desktopVw(55)};
      span {
        :nth-child(2) {
          font-size: ${desktopVw(30)};
        }
      }
    }
  }
`

const StyledSpan = styled.span`
  color: ${colors.white};
  font-size: ${mobileVw(18)};
  line-height: ${mobileVw(20)};
  font-family: ${fonts.mrs};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    line-height: ${desktopVw(24)};
  }
`

const TextWrapper = styled.div`
  background-color: ${colors.black};
  padding: ${mobileVw(8)} ${mobileVw(16)} ${mobileVw(30)};
  line-height: ${mobileVw(14)};

  p {
    margin: 0;
    padding-bottom: ${mobileVw(20)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(20)};
    line-height: normal;
    padding: ${desktopVw(20)} ${desktopVw(260)} ${desktopVw(40)};

    p {
      margin: 0;
      padding-bottom: ${desktopVw(20)};
    }
  }
`
const StyledButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['hideButton'].includes(prop),
})`
  display: ${(props) => (props.hideButton ? 'none' : ' block')};
  width: 90vw;
  margin: 2.5vw auto 0px;

  @media (min-width: ${desktopBreakpoint}) {
    display: ${(props) => (props.hideButton ? 'none' : ' block')};
     max-width: 25.6223vw;
     margin : 0.366032vw auto 0px;
  }
`

const FeaturedArticlesWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    button {
      margin-bottom: 0;
    }
    padding-left: ${desktopVw(132)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    padding-left: ${desktopVw(128)};
  }
`

export const FeaturedVideoWrapper = styled(FeaturedItemWrapper)`
  padding-top: 0;
  margin-top: 0;

  h2 {
    font-size: ${mobileVw(35)};
    line-height: ${mobileVw(32)};
  }

  span {
    padding-bottom: ${mobileVw(8)};
  }

  video {
    min-height: ${mobileVw(370)};
  }

  button {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(260)};

    h2 {
      font-size: ${desktopVw(54)};
      line-height: ${desktopVw(32)};
    }

    span {
      display: inline-block;
      padding-bottom: ${desktopVw(8)};
      :nth-child(1) {
        padding-right: ${desktopVw(8)};
      }

      :nth-child(2) {
        width: auto;
      }
    }

    video {
      min-height: ${desktopVw(400)};
    }

    button {
      display: none;
    }
  }
`

const FeaturedSectionWrapper = styled(FeaturedVideoWrapper)`
  h2 {
    font-size: ${mobileVw(30)};
  }

  span {
    display: inline-block;

    :nth-child(1) {
      padding-right: ${mobileVw(4)};
    }
  }

  button {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(260)} 0;

    h2 {
      font-size: ${desktopVw(54)};
    }

    span {
      :nth-child(1) {
        padding-right: ${desktopVw(8)};
      }
    }
  }
`

export const maisonMaille = graphql`
  query MaisonMaille($id: String!, $node_locale: String!) {
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      node_locale
    }
    contentfulPageLaMaisonMaille(id: { eq: $id }) {
      seoDescription
      seoTitle
      id
      mobileHeroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featuredSection {
        title
        featuredItem {
          ... on ContentfulPageRecipeLanding {
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            slug
          }
        }
      }
      featuredSectionText {
        raw
      }
      featuredItem {
        title
        buttonText
        featuredItem {
          ... on ContentfulPageHistory {
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            slug
          }
        }
      }
      #Fetch Article Category -Start
      articleCategory {
        title
        slides {
          slug
          thumbnailImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          title:name
        }
      }
    #Fetch Article Category -End

      video {
        title
        buttonText
        featuredItem {
          ... on ContentfulComponentVideo {
            name
            poster {
              fluid {
                src
              }
            }
            videoMov {
              file {
                url
              }
            }
            videoMp4 {
              file {
                url
              }
            }
            videoTrackFile {
              file {
                fileName
                url
              }
            }
          }
        }
      }
      heroblock {
        title
        background
        breadcrumbsTitle
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      relatedArticles {
        createdAt
        date(formatString: "DD-MM-YYYY")
        author
        subtitle
        title
        slug
        category
        thumbnailImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      historyVideo {
        title
        buttonText
        featuredItem {
          ... on ContentfulComponentVideo {
            name
            poster {
              fluid {
                src
              }
            }
            videoMov {
              file {
                url
              }
            }
            videoMp4 {
              file {
                url
              }
            }
            videoTrackFile {
              file {
                fileName
                url
              }
            }
          }
        }
      }
    }
  }
`

export default MaisonMaille
